import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const sectionStory = () => {
	const sectionStory = document.querySelectorAll("[data-section='story']");

	if (sectionStory) {
		sectionStory.forEach((sectionStoryItem) => {
			const sectionStoryImage = sectionStoryItem.querySelector(".image > img");

			const sectionStoryTL = gsap.timeline({ 
				defaults: {
					ease: "power4.out",
					// clearProps: "all",
				},
			});

			sectionStoryTL.set(sectionStoryImage, { scale: 1.5 }).to(sectionStoryImage, { scale: 1 }, 0);

			ScrollTrigger.create({
				animation: sectionStoryTL,
				trigger: sectionStoryImage,
				start: "top bottom",
				end: "bottom top",
				scrub: 5,
			});
		});
	}
};

export default sectionStory;

// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";



const componentSlider = () => {
    // const galleries = document.querySelectorAll('.pswp-gallery');

    // if (galleries) {

        // init Swiper:

var swiper = new Swiper(".swiper", {
  modules: [Navigation, Pagination],
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  threshold: 10,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

    }
// };

export default componentSlider;
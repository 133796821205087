import gsap from "gsap";

const leavePage = (container, done) => {
	const overlay = document.querySelector(".transition-overlay");
	const overlayTile = document.querySelectorAll(".transition-overlay__tile");

	const leavePageTL = gsap.timeline({
		defaults: {
			ease: "expo.out",
			// clearProps: "all",
		},
		onComplete: () => done(),
	});

	leavePageTL
		.set(overlay, { visibility: 'visible' })
		.to(overlayTile, {
			y: 0,
			stagger: {
				each: 0.1,
				from: "start",
				grid: [4, 1],
			},
		});

	return leavePageTL;
};

export default leavePage;

import gsap from "gsap";

const enterPage = (container) => {
	const overlay = document.querySelector(".transition-overlay");
	const overlayTile = document.querySelectorAll(".transition-overlay__tile");

	const enterPageTL = gsap.timeline({
		defaults: {
			ease: "expo.in",
			clearProps: "all",
		},
	});

	enterPageTL
        .to(overlayTile, {
            y: '-100%',
            stagger: {
                each: 0.1,
                from: "start",
                grid: [4, 1],
            },
        })
        .set(overlay, { visibility: 'hidden' })

	return enterPageTL;
};

export default enterPage;

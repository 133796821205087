import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const sectionHalf = () => {
	const sectionHalf = document.querySelectorAll("[data-section='half']");

	if (sectionHalf) {
		sectionHalf.forEach((sectionHalfItem) => {
			const sectionHalfImage = sectionHalfItem.querySelector(".image > img");

			const sectionHalfTL = gsap.timeline({
				defaults: {
					ease: "power4.out",
					// clearProps: "all",
				},
			});

			sectionHalfTL.set(sectionHalfImage, { scale: 1.5 }).to(sectionHalfImage, { scale: 1 }, 0);

			ScrollTrigger.create({
				animation: sectionHalfTL,
				trigger: sectionHalfImage,
				start: "top bottom",
				end: "bottom top",
				scrub: 5,
			});
		});
	}
};

export default sectionHalf;

import gsap from "gsap";

const componentAnimation = () => {
    
    const dataAnimations = document.querySelectorAll("[data-animation='default']");

    if (dataAnimations) {
        dataAnimations.forEach(function (dataAnimation) {
            gsap.set(dataAnimation, { opacity: 0, y: 100});
    
            const observeOptions = {
                root: null,
                threshold: 0.25,
                // rootMargin: "-150px"
            };

            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        return;
                    } else {
                        gsap.to(dataAnimation, {duration: 1, opacity: 1, y: 0});
                        observeAni.unobserve(entry.target);
                    }
                });
            }, observeOptions);

            observeAni.observe(dataAnimation);
        });
    }
};

export default componentAnimation;

import gsap from "gsap";

const sideNavigation = () => {
	const sideNav = document.querySelector(".side-navigation");
    
	if (sideNav) {
		const sideNavBtn = document.querySelector(".side-navigation__button");
		const sideNavPopup = document.querySelector(".side-navigation__popup");
		const sideNavLink = document.querySelectorAll(".side-navigation__link");
		const sections = document.querySelectorAll("section");

		const observeSectionOptions = {
			root: null,
			rootMargin: '50px',
			threshold: [0.5],
		};

		const observerSections = new IntersectionObserver(function (entries, observer) {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					return;
				} else {
					sideNavLink.forEach((link) => {
						const tester = link.hash.split("#").pop();
						if (tester == entry.target.id) {
							link.classList.add("current");
							sideNavBtn.innerText = link.getAttribute("title");
						} else {
							link.classList.remove("current");
						}
					});
				}
			});
		}, observeSectionOptions);

		sections.forEach((section) => {
            if (section.hasAttribute('id')) {
                observerSections.observe(section);
            }
		});

		//IntersectionObserver for Number Teaser

		sideNavLink.forEach((link) => {
			link.addEventListener("click", (e) => {
				e.preventDefault();
				gsap.to(window, {
					duration: 1,
					scrollTo: {
						y: link.hash,
					},
				});
			});
		});

		var timeoutOut = null;

		const popupAnimation = gsap.timeline({
			paused: true,
			reversed: true,

			defaults: {
				ease: "power3.out",
			},
		});

		popupAnimation
			.from(sideNavPopup, {
				opacity: 0,
				visibility: "hidden",
				duration: 0,
			})
			.to(sideNavPopup, {
				duration: 0.5,
				opacity: 1,
				marginBottom: 20,
				visibility: "visible",
			});

		const sidePopupOpen = () => {
			setTimeout(() => {
				sideNavBtn.setAttribute("aria-expanded", "true");
				popupAnimation.play();
			});
		};

		const sidePopupClose = () => {
			setTimeout(() => {
				sideNavBtn.setAttribute("aria-expanded", "false");
				popupAnimation.reverse();
			});
		};

		sideNavBtn.addEventListener("click", () => {
			if (sideNavBtn.getAttribute("aria-expanded") == "false") {
				sidePopupOpen();
			} else {
				sidePopupClose();
			}
		});

		sideNav.addEventListener("mouseover", () => {
			clearTimeout(timeoutOut);
		});

		sideNav.addEventListener("mouseout", () => {
			timeoutOut = setTimeout(() => {
				sidePopupClose();
			}, 2000);
		});
	}
};

export default sideNavigation;

import gsap from "gsap";

const sideTeaser = () => {
    const sideTeaser = document.getElementById("sideTeaser");

    if (sideTeaser) {
        const sideTeaserClose = sideTeaser.querySelector(".side-teaser__close");
        const removeSideTeaser = () => {
            sideTeaser.style.display = "none";
        }

        gsap.fromTo(sideTeaser, { opacity: 0, y: "20px" }, { opacity: 1, y: 0, delay: 3, pointerEvents: "auto" });

        sideTeaserClose.addEventListener("click", () => {
            gsap.to(sideTeaser, { opacity: 0, y: "20px", onComplete: removeSideTeaser });
        });
    }
};

export default sideTeaser;

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const sectionFull = () => {
	const sectionFull = document.querySelectorAll("[data-section='full']");

	if (sectionFull) {
		sectionFull.forEach((sectionFullItem) => {
			const sectionFullImage = sectionFullItem.querySelector(".image > img");

			const sectionFullTL = gsap.timeline({
				defaults: {
					ease: "power4.out",
					// clearProps: "all",
				},
			});

			sectionFullTL.set(sectionFullImage, { scale: 1.5 }).to(sectionFullImage, { scale: 1 }, 0);

			ScrollTrigger.create({
				animation: sectionFullTL,
				trigger: sectionFullImage,
				start: "top bottom",
				end: "bottom top",
				scrub: 5,
			});
		});
	}
};

export default sectionFull;

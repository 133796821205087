export class StepNavigation extends HTMLElement {
	constructor() {
		super();
	}

	static get observedAttributes() {
		return ["prev", "prevTitle", "next", "nextTitle"];
	}

	attributeChangedCallback() {
		this.render();
	}

	get prev() {
		return this.getAttribute("prev");
	}

	get prevTitle() {
		return this.getAttribute("prev-title");
	}

	get next() {
		return this.getAttribute("next");
	}

	get nextTitle() {
		return this.getAttribute("next-title");
	}

	render() {
		this.innerHTML = `
        <a href="${this.prev}" class="stepnavigation-left ${this.prev ? "" : "stepnavigation--deactive"}"  title="${this.prevTitle}">
            <div class="stepnavigation__btn" aria-label="Button – Vorheriger Seite">
                <span class="stepnavigation__title">${this.prevTitle}</span>
            </div>
        </a>
        <a href="${this.next}" class="stepnavigation-right ${this.next ? "" : "stepnavigation--deactive"}" title="${this.nextTitle}">
            <div class="stepnavigation__btn" aria-label="Button – Nächste Seite">
                <span class="stepnavigation__title">${this.nextTitle}</span>
            </div>
        </a>
        `;
	}
}